
 function convertToUTC(date) {
  var now = new Date(date);
  var now_utc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
  return now_utc
 }

function buildLineChart(e){
  var input = $(e);
  var series_indicador = [];
  var data = input.data('historico');
  var reference = input.data('reference');
  var newdata = []
  // data.forEach(function(currentValue, index, arr){
  //   newdata.push([ convertToUTC(currentValue[0]),currentValue[1]] )
  // })

  series_indicador = [
      {
          name: input.data('serie-name'),
          data: data
      }
  ]

  Highcharts.chart(input.attr('id'),
   {

      title: {
          text: input.data('title')
      },

      yAxis: {
          title: {
              text: input.data('indicador-parametro')
          }
      },
      xAxis: {
       categories: reference
      },
      legend: {
      },

      plotOptions: {
          series: {
              pointStart: input.data('inicial')
          }
      },

      series: series_indicador,
      responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

  });
 }

 function buildMultLineChart(e) {
    let input = $(e);
    Highcharts.chart(input.attr('id'), {

        title: {
            text: input.data('title'),
            align: 'center'
        },
    
        yAxis: {
            title: {
                text: input.data('indicador-parametro')
            }
        },
    
        xAxis: {
            categories: input.data('reference')
        },
    
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
    
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: input.data('inicial')
            }
        },
    
        series: input.data('series'),
    
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    
    });
    
 }

export { buildLineChart, buildMultLineChart };
