

/* ---------- Select2  ---------- */
  function enableSelect2(){

    $("select.selectable").select2(
    {
      allowClear: true,
      placeholder: "Digite para pesquisar...",
      width: 'resolve',
      selectOnBlur: true
    });
  };

    /*------With ajax ---- */

  function enableAutoComplete() {
    $('.autocompletable').each(function(i,e){
      makeAutoComplete(i,e)
    });
    /*----multi ------*/

    $('.tokenable').each(function(i, e){
      makeAutoComplete(i,e)
    });

    $('.external-select').each(function(i, e){
      makeExternalSelect(i,e)
    });
  };

  function makeAutoComplete(i,e){
    var input = $(e);
    var multiple = input.data('multiple');
    if (multiple === true) {
      input.val(input.data('init-value'));
      input.trigger('change');
    }
    input.select2({
      placeholder: input.data('placeholder'),
      width: 'resolve',
      multiple: multiple,
      selectOnBlur: true,
      ajax: {
          dataType: 'json',
          quietMillis: 500,
          data: function (params) {
              return {
                  query: params.term, // search term
                  page: params.page,
                  per: input.data("max-results")
              };
          },
          processResults: function (data, params) {
            params.page = params.page || 1;
            return {
                results: data,
                pagination: {
                  more: (params.page * 10) < data.count_filtered
                }
            };
          }
      }
    });

  };

  function autoCompleteInitValues(i,e) {

    // Fetch the preselected item, and add to the control
    // var input = $(e);
    // console.log(e);
    // var values = e.options.map(function() {
      // return [this.value, this.text];
    // })
    // console.log(values);
    // var options = input.options;
    // options.forEach(function(option,index){
    //   console.log(option);
    // })
    // $.ajax({
    //     type: 'GET',
    //     url: '/api/students/s/' + studentId
    // }).then(function (data) {
    //     // create the option and append to Select2
    //     var option = new Option(data.full_name, data.id, true, true);
    //     input.append(option).trigger('change');

    //     // manually trigger the `select2:select` event
    //     input.trigger({
    //         type: 'select2:select',
    //         params: {
    //             data: data
    //         }
    //     });
    // });
  }

  function makeExternalSelect(i,e){
    var input = $(e);
    var multiple = input.data('multiple');
    input.select2({
      placeholder: input.data('placeholder'),
      allowClear: true,
      width: 'resolve',
      multiple: multiple,
      selectOnBlur: true,
      ajax: {
        url: input.data('ajax-url'),
        dataType: 'json',
        results: function (data, page) {
              return {results: data};
          }
      }
    })
  }


export {enableSelect2, enableAutoComplete, makeAutoComplete};
