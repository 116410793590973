/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
require("jquery")

window.$ = $;
window.jquery = jQuery;


import('./bootstrap.min');
require('@popperjs/core');
import * as nifty from './nifty';
import pace from './pace.min';
require('jquery-mask-plugin');
// import('./jquery.maskMoney.min');
require('bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.pt-BR');
require('select2');
require('select2/src/js/select2/i18n/pt-BR');
require("./plugins/bootstrap-timepicker/bootstrap-timepicker");
// import "@fortawesome/fontawesome-free/js/all";
import * as customScripts from './scripts'
import {enableSelect2, enableAutoComplete, makeAutoComplete} from './select-2-config'
// import '../stylesheets/application'

import Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/series-label')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
import {buildLineChart} from './charts';
import {enableExportTable} from './html_table_to_csv';
require("@nathanvda/cocoon");

window.buildLineChart = buildLineChart;
window.customScripts = customScripts
window.enableAutoComplete = enableAutoComplete;
window.enableDatePicker = customScripts.enableDatePicker;
window.enableTimePicker = customScripts.enableTimePicker;
window.enableDinamicSelect = customScripts.enableDinamicSelect;
window.enableExportTable = enableExportTable;
window.enableMaskedInput = customScripts.enableMaskedInput;
window.growAlert = customScripts.growAlert;
window.Highcharts = Highcharts;
window.pace = pace;
window.funcionarioAtribuicaoDocs = customScripts.funcionarioAtribuicaoDocs;
window.makeAutoComplete = makeAutoComplete;
window.searchLocalBarChange = customScripts.searchLocalBarChange;
window.updateChildren = customScripts.updateChildren;
window.PrintElem = customScripts.PrintElem;
window.changeRestituicao = customScripts.changeRestituicao;
window.fic_srei_ausencia = customScripts.fic_srei_ausencia;
window.forceNumeric = customScripts.forceNumeric;

function ready(){
  // customScripts.enableMaskMoney();
  // enableEstadoCidade();
  customScripts.enableDatePicker();
  customScripts.enableTimePicker();
  customScripts.enableEstadoCidade();
  customScripts.enableDinamicSelect();
  customScripts.enableJsFlash();
  customScripts.enableMaskedInput();
  customScripts.enableNotificationRead();
  customScripts.enableRemoteDownload();
  customScripts.enableSetMax();
  customScripts.enableSort();
  //
  enableAutoComplete();
  enableExportTable();
  enableSelect2();
  // customScripts.updateNotifications();
  //setInterval(customScripts.updateNotifications, 120000);
//   // enableSort();
}
window.ready = ready;
document.addEventListener("DOMContentLoaded", ready);
document.addEventListener("turbolinks:load", () => {
  ready();
})

$('.cocoon-container').on('cocoon:after-insert', function(e, insertedItem) {
  ready();
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// //
// const images = require.context('/app/assets/images', true)
// const imagePath = (name) => images(name, true)

require("trix");
require("@rails/actiontext");

// Trix.config.lang={attachFiles:"Anexar arquivos",bold: "Negrito",
//     bullets: "Lista Marcadores",  byte: "Byte",  bytes: "Bytes",
//     captionPlaceholder: "captionPlaceholder",  code: "Código",
//     heading1: "Título",  indent: "Aumentar Recuo",  italic: "Itálico",
//     link: "Link",  numbers: "Lista Numerada",  outdent: "Dimunir Recuo",
//     quote: "Citação",  redo: "Refazer",  remove: "Remover",
//     strike: "Tachado",  undo: "Desfazer",  unlink: "Remover Link",
//     url: "URL",  urlPlaceholder: "Marcador de URL...",  GB: "GB",  KB: "KB", MB: "MB", PB: "PB",
//     TB: "TB"}

window.addEventListener("trix-file-accept", function(event) {
  event.preventDefault()
  alert("File attachment not supported!")
})

